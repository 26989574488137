import React from 'react';
import { Box, Typography, Link, IconButton } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GCWheel from '../images/GCwheel.svg';
import { useTheme } from '@mui/material/styles';

const Footer = () => {
    const theme = useTheme();
  return (
    <Box py={5} style={{ backgroundColor: theme.palette.secondary.main}} color="#fff">
 
      <Box my={3} borderBottom={1} borderColor="#fff" />
      <Box display="flex" justifyContent="space-evenly" alignItems="center">
        <Typography variant="body2" align="left">
          © 2023 Growth Captains. All rights reserved.
        </Typography>
        {/*<Box>
          <IconButton href="https://www.facebook.com/yourpage" color="inherit">
            <FacebookIcon />
          </IconButton>
          <IconButton href="https://www.twitter.com/yourpage" color="inherit">
            <TwitterIcon />
          </IconButton>
          <IconButton href="https://www.linkedin.com/yourpage" color="inherit">
            <LinkedInIcon />
          </IconButton>
        </Box>*/}
      </Box>
    </Box>
  );
};

export default Footer;